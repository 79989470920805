.App {
  text-align: center;
  background-color: black;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}

.bd-mode-toggle {
  z-index: 1500;
}

.container {
  max-width: 960px;
}

/*
 * Custom translucent site header
 */

.site-header {
  background-color: rgba(0, 0, 0, .85);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.site-header a {
  color: #8e8e8e;
  transition: color .15s ease-in-out;
}

.site-header a:hover {
  color: #fff;
  text-decoration: none;
}


.flex-equal>* {
  flex: 1;
}

@media (min-width: 768px) {
  .flex-md-equal>* {
    flex: 1;
  }
}

a:link {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.container {
  position: relative;
  width: 50%;
}

.container img {
  width: 100%;
  height: auto;
}

.container .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.container .btn:hover {
  background-color: black;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}


@media (max-width: 1767px) {
  .yourClassname {
    display: none;
  }

  #textEffect {
    display: none;
  }

}

#marginContact {
  margin-top: 600px;
}

#logoHome {
  width: 600px;

}

@media (max-width: 767px) {
  #marginContact {
    margin-top: 80px;
    padding-right: 40px;
  }

  #buttonContact {
    background: radial-gradient(circle, rgba(31, 67, 124, 0.6) 0%, rgba(0, 0, 0, 0.1) 83%);
    margin-bottom: 20px;
  }

  #logohome {
    margin-top: 50px;
  }

}

#bodyColor {
  background: rgb(4, 10, 122);
  background: linear-gradient(0deg, rgb(23, 3, 112) 47%, rgb(0, 0, 0) 77%);

}


@media (max-width: 767px) {

  #bodyColor {
    background: rgb(4, 10, 122);
    background: linear-gradient(0deg, rgb(10, 3, 72) 67%, rgb(0, 0, 0) 97%);
  }


  #contactColorForm {
    background: rgba(0, 0, 0, 0.9)
  }

  #siaeImg {
    width: 90%
  }

  #textHeader {
    position: absolute;
    top: 500px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  #firstImg {
    opacity: 1;
  }
}

#firstImg {
  background: 0 0 0 0, 0.7;
}




#textEffect {
  padding-top: 20%;
  font-size: 100%;
}

ul {
  list-style-type: none;
}

.zoom {
  transition: transform .4s;
  /* Animation */

}

.zoom:hover {
  transform: scale(1.1);

}




#siaeImgEffectRighe {
  --g: 4px;
  /* the gap */
  --b: 6px;
  /* border thickness*/
  --c: #1195d2;
  /* the color */


  padding: calc(var(--g) + var(--b));
  --_c: #0000 0 25%, var(--c) 0 50%;
  --_g1: repeating-linear-gradient(90deg, var(--_c)) repeat-x;
  --_g2: repeating-linear-gradient(180deg, var(--_c)) repeat-y;
  background:
    var(--_g1) var(--_p, 25%) 0, var(--_g2) 0 var(--_p, 125%),
    var(--_g1) var(--_p, 125%) 100%, var(--_g2) 100% var(--_p, 25%);
  background-size: 200% var(--b), var(--b) 200%;
  cursor: pointer;
  filter: grayscale(50%);
  transition: .3s;
}



#siaeImgEffectRighe:hover {
  --_p: 75%;
  filter: grayscale(0%);
}


.testdiv:hover>.testcard:not(:hover) {
  filter: blur(5px);
}

.i:hover {
  box-shadow: 2px 2px 9px greenyellow;
}

iframe-container {
  overflow: hidden;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 400px;
  width: 700px;
}

@media (max-width: 767px) {
  .iframe-container iframe {
    border: 0;
    height: 200px;
    width: 300px;
  }
}