
.text{
    color: white;
    text-align: left;

}

@media (max-width: 767px) {
    .text{
        font-size: 10px;
    }
}