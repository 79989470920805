.btnr {
    padding: 1em 2em;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    letter-spacing: 3px;
    text-transform: uppercase;
    color:  rgb(0, 225, 72);
    transition: all 1000ms;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    outline: 2px solid  rgb(0, 225, 72);
    background-color: #0808085b;
    margin-top: 40px;
  }
  
  .btnr:hover {
    color: #040404;
    transform: scale(1.1);
    outline: 2px solid #000000;
    box-shadow: 4px 5px 17px -4px  rgb(99, 183, 110);
  }
  
  .btnr::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
    background-color:  rgb(0, 225, 72);
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
  }
  
  .btnr:hover::before {
    width: 250%;
  }
  