
@import url("https://fonts.googleapis.com/css2?family=Sura:wght@400;700&display=swap");

:root {
  --size: 60vmin;
  --space: 8vmin;
  --duration: 1.5s;
  --ease-out: cubic-bezier(0.25, 1, 0.5, 1);
  --bounce-out: cubic-bezier(0.34, 1.56, 0.64, 1);
}

* {
  box-sizing: border-box;
}


.promo {
  position: relative;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  transition: transform .6s;

}

.image-wrapper {
  width: var(--size);
  height: var(--size);
  overflow: hidden;
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 0 50%, 0 0);
  transition: transform var(--duration) var(--ease-out),
    clip-path var(--duration) var(--ease-out);
    
}

.image-wrapper img {
  position: relative;
  width: 120%;
  height: 100%;
  object-fit: cover;
  transform: translateX(-10%);
  transition: transform var(--duration) var(--ease-out);
}

.promo.active img {
  transform: translateX(0);
  


}

.promo.active .image-wrapper {
  clip-path: polygon(75% 0, 100% 50%, 75% 100%, 0 100%, 25% 50%, 0 0);
  transform: translateX(25%);
  transition-timing-function: var(--bounce-out);
}

.title {
  --font-size: calc(var(--size) / 8);
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: var(--font-size);
  font-weight: 700;
  line-height: 1.2;
  white-space: nowrap;
  transform: translate(-10%, -50%);
  transition: transform var(--duration) var(--ease-out);
}

.title::after {
  content: attr(data-cta);
  display: inline-block;
  margin-left: 1.5vmin;
  font-size: calc(var(--font-size) / 3.25);
  font-weight: 400;
  letter-spacing: 0.125vmin;
  opacity: 0;
  transform: translateX(-25%);
  transition: transform var(--duration) var(--ease-out),
    opacity var(--duration) var(--ease-out);
}

.image-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-color);
  mix-blend-mode: multiply;
  opacity: 0;
  transform: translateZ(0);
  transition: opacity var(--duration) var(--ease-out);
}

.promo.active .title {
  transform: translate(5%, -50%);
  transition-timing-function: var(--bounce-out);
}

.promo.active .title:after {
  opacity: 1;
  transform: translateX(0);
  transition-timing-function: var(2 --bounce-out);
}

.promo.active .image-wrapper::after {
  opacity: 1;
}

.textzoom{
  transition: transform .40s;

}
.textzoom.zoomeffect{
  transform: scale(1.1);
}





#chi-siamo-text {
    color: white;
    text-align: left;
    font-weight: normal; 
    
}

#chi-siamo-titles{
    color: rgb(0, 255, 72);
    text-align: left;
    margin-bottom: 25px;

}

#marginContact{
  bottom:350px; 
  margin-bottom:'550px'; 
  position:'relative';
}

@media (max-width: 767px) {


    #chi-siamo-text {
        font-size: 13px;
    }

    #marginContact {
      bottom: 0;
    }
 
   

}