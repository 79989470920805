* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

h6,
h1,
h2,
h3 {
	text-transform: uppercase;
	color: #fff;
}

.h2_market {
	text-align: left;
	font-size: 1.125em;
	color: #4a89ca;
	font-weight: 600;
	margin: 0;
}

.h3_markey {
	font-size: 1.2em;
	line-height: 1.25em;
	margin-top: .85em;
	margin-bottom: .5em;
}

.p_market {
	text-align: left;
	font-size: .875em;
	line-height: 1.4;
	margin: 0 0 1.5em;
}

/* ================================= 
  Base Layout Styles
==================================== */

/* ---- Layout Containers ---- */


.content {
	margin: auto;
}

.main-header {
	text-align: center;
	padding: 2.8em 0 3.8em;
}

.cube-container {
	max-width: 200px;
	text-align: center;
	margin: 0 auto 7.5em;
}

/* ---- Page Elements ---- */

.name {
	font-size: 1.65em;
	font-weight: 800;
	margin: 0 0 1.5em;
	line-height: 1;
	color: #fff;
	margin-top: 100px;
}

.name span {
	font-weight: 300;
	margin-left: -7px;
}

.logo {
	width: 45px;
	margin-bottom: .4em;
	cursor: pointer;
}

.button {
	margin-left: 11px;
	font-size: .9em;
	width: 90%;
	color: #fff;
	line-height: 1.15;
	font-weight: 700;
	display: block;
	text-decoration: none;
	text-transform: uppercase;
	padding: .95em 0;
	border-radius: .5em;
}

/* ---- Photo Overlay ---- */

.photo-desc {
	font-size: .85em;
	color: #fff;
	padding: 1.1em 1em 0;
	background: radial-gradient(circle, rgba(0, 7, 112, 1) 0%, rgba(0, 0, 3, 1) 100%);
}

/* ---- Float clearfix ---- */

.clearfix::after {
	content: " ";
	display: table;
	clear: both;
}

/* ================================= 
  Media Queries
==================================== */

@media (min-width: 769px) {
	.cube-container {
		float: left;
		margin-left: 19.6%;
	}
}

@media (min-width: 1025px) {
	.cube-container:first-child {
		margin-left: 0%;
	}

	.cube-container:last-child {
		float: right;
	}

	.content {
		max-width: 1000px;
		margin: auto;
	}
}

/* ================================= 
  Button Transitions
==================================== */

.button {
	transition: background .3s;
}

.button:hover {
	border-bottom: 2px solid rgb(0, 225, 72);
	color: rgb(0, 225, 72);

}

/* ================================= 
  Photo 3D Transforms & Transitions
==================================== */

.cube-container {
	perspective: 800px;
}

.photo-cube {
	transition: transform 3.5s ease-in-out;
	width: 245px;
	height: 245px;
	transform-style: preserve-3d;
}

.photo-cube:hover {
	transform: rotateY(-270deg);
}

.front,
.back,
.left,
.right {
	border: 3px solid rgb(0, 0, 0);
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
}

.front {
	transform: translate3d(0, 0, 135px);
}

.back {
	transform: translateZ(-110px) rotateY(270deg);
	transform-origin: center left;
}

.left {
	transform: rotateY(-270deg) translate3d(110px, 0, 0);
	transform-origin: top right;
}

.right {
	transform: translateZ(-107px) rotateY(180deg);
}

@media (max-width: 767px) {
	.photo-cube {
		width: 224px;
     	height: 224px;
	}
	.front {
		transform: translate3d(0, 0, 112px);
	}
	.cube-container {
		margin-left: 17.6%;
	}

}