

#matrimonio-text {
    color: white;
    text-align: left;
    font-weight: normal; 
    
}


#lastElement{

    margin-top: 160px;
    margin-bottom: 160px;
}

#rowElement{
    
    margin-top: 160px;
}


#mainElement{
    margin-top: 100px;
}

#firstImg{
    max-height: 1400px;
    max-width: 100%;
    right: 3px;
    position: absolute;
    opacity: 0.5;
}

@media (max-width: 767px) {
   
    #mainElement {
        margin-top: -60px;
        padding-left: 9%;
    }
    #rowElement{
        margin-top: 50px;
    }
    #lastElement{
        margin-top: 50px;
        margin-bottom: 60px;

    }
    #firstImg{
        max-height: 60%;
    }
}


  
